/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

.list-label {
  color: var(--ion-color-medium-tint);
}

.list-signature {
  max-width: 100px;
  max-height: 100px;
}

.ion-text-xs {
  font-size: 10px;
}

.item-has-focus {
  --highlight-background: var(--ion-color-primary);
}

ion-toast::part(message) {
  white-space: pre-line;
}

ion-segment-button {
  text-transform: none;
}

app-time-picker {
    display: flex;
    flex: 1;
    align-items: center;
    -ms-flex: 1;
    -ms-flex-align: center;
    width: 100%;
}

.logged-user {
  font-weight: bold;
  font-size: 10px;
}

/*
ion-title {
  padding-left: 60px;
}
*/

loading-indicator {
  position: absolute;
  height: 100%;
  width: 100%;
}
